import { patch } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { useMutation, type UseMutationOptions } from "@tanstack/react-query";
import { z } from "zod";

import {
  JobType,
  type PlacementCandidateMarketingLinkClicks,
  PlacementCandidateMarketingLinkClicksSchema,
  PlacementCandidateStatus,
  type PreferredCandidateArea,
  ShiftNameType,
} from "../types";

interface UpdatePlacementCandidateParams {
  placementCandidateId: string;
  status?: PlacementCandidateStatus;
  jobTypesInterested?: JobType[];
  shiftTypesInterested?: ShiftNameType[];
  preferredAreas?: PreferredCandidateArea[];
  marketingLinkClicks?: PlacementCandidateMarketingLinkClicks;
}

const updatePlacementCandidateRequestSchema = z.object({
  data: z.object({
    attributes: z.object({
      status: z.nativeEnum(PlacementCandidateStatus).optional(),
      jobTypesInterested: z.array(z.nativeEnum(JobType)).optional(),
      shiftTypesInterested: z.array(z.nativeEnum(ShiftNameType)).optional(),
      preferredAreas: z
        .array(
          z.object({
            location: z.object({
              type: z.literal("Point"),
              coordinates: z.tuple([z.number(), z.number()]),
            }),
            address: z.object({
              city: z.string().optional(),
              country: z.string().optional(),
              countryCode: z.string().optional(),
              formatted: z.string().optional(),
              line1: z.string().optional(),
              postalCode: z.string().optional(),
              region: z.string().optional(),
              subpremise: z.string().optional(),
              state: z.string().optional(),
              stateCode: z.string().optional(),
              streetName: z.string().optional(),
              streetNumber: z.string().optional(),
              metropolitanStatisticalArea: z.string().optional(),
            }),
            distance: z.number(),
          })
        )
        .optional(),
      marketingLinkClicks: PlacementCandidateMarketingLinkClicksSchema.optional(),
    }),
    id: z.string(),
    type: z.literal("placement-candidates"),
  }),
});

export function useUpdatePlacementCandidate(
  options: UseMutationOptions<string, Error, UpdatePlacementCandidateParams> = {}
) {
  return useMutation({
    mutationFn: async (params) => {
      const {
        placementCandidateId,
        status,
        jobTypesInterested,
        shiftTypesInterested,
        preferredAreas,
        marketingLinkClicks,
      } = params;
      const response = await patch({
        url: `${environmentConfig.REACT_APP_API_MAIN_URL}/api/placement-candidates/${placementCandidateId}`,
        data: {
          data: {
            attributes: {
              status,
              jobTypesInterested,
              shiftTypesInterested,
              preferredAreas,
              marketingLinkClicks,
            },
            id: placementCandidateId,
            type: "placement-candidates",
          },
        },
        requestSchema: updatePlacementCandidateRequestSchema,
        responseSchema: z.string(),
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.UPDATE_PLACEMENT_CANDIDATE_FAILURE,
      useErrorMessage: "Failed to update candidate profile",
    },
    ...options,
  });
}
